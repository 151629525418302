$menu-width: 250px;

.container {
    :global {
        .tab-menu > .ui.grid {
            & > .column:first-of-type {
                max-width: $menu-width !important;
            }

            & > .column:last-of-type {
                width: calc(100% - #{$menu-width}) !important;
                height: calc(100vh - 120px);
            }
        }

        .menu.vertical {
            .item.active {
                font-weight: bold;
                color: #011c71;
                background-color: rgba(0, 51, 154, 0.1);
            }
        }
    }
}
