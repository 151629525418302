.container {
  list-style: none;
  // margin: 0;
  // position: fixed;
  // bottom: 0;
  //max-width: 800px;
  width: fit-content;
  top: 51px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  position: fixed;
  // right: 0;
  color: #ffffff;
  padding: 0;
  z-index: 9999;

  li {
    padding: 0 10px 10px;
    margin-top: 2px;

    :global {
      .ui.icon.message {
        padding: 3px;
      }
      .ui.icon{
        i{
          margin-right: 10px;
        }
        .content {
          margin-right: 72px;
          font-weight: bold;
        }
      }
      .ui.icon.success.message > .icon.close {
        &::before{
          font-size: 14px;
          
        }
        
      }
      .ui.icon.success.message > .icon:not(.close) {
        &::before{
          font-size: 30px;
          color: #1E561F !important;
        }
        
      }

      .ui.icon.warning.message > .icon:not(.close) {
        &::before{
          font-size: 30px;
          color: #912d2b;
        }
        
      }
      .ui.icon.error.message > .icon:not(.close) {
        &::before{
          font-size: 30px;
          color: #912d2b;
        }
        
      }
      .ui.icon.warning.message > .icon.close {
        &::before{
          font-size: 14px;
          color: #912d2b;
        }
        
      }
      .ui.icon.error.message > .icon.close {
        &::before{
          font-size: 14px;
          color: #912d2b;
        }
        
      }
    }
  }
}
