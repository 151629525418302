@import 'src/assets/styles/_variables.scss';
.mainContainer {
    // border: 1px solid black;
    padding: 13px;
    // min-height: 100vh;
    height: calc(100% - 90px);
    //overflow: hidden;
}

.gridWrapper {
    // border: 1px solid black;
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.sideMenu {
    // box-shadow: 0px 1px 3px #00000029;
    // border: 1px solid #e3e3e3;
    list-style: none;
}

.rightPanel {
    // border: 1px solid blue;
    background-color: #ffffff;
    height: 100%;
    box-shadow: 0px 2px 4px #00000029;
    border: 1px solid #e6e6e7;
}

summary::marker {
    color: blue;
    font-size: 0em;
    list-style-type: none;
}
