@import 'src/assets/styles/_variables.scss';
.wrapper {
    display: flex;
    flex-direction: row;
    background-color: $background-color-1;
    padding-left: 20px;
    padding-right: 20px;

    h1.header {
        padding-top: 4em !important;
        font-size: 4em;
    }
    i {
        background: none;
        &::before {
            font-size: 13px;
            position: relative;
            top: 0;
        }
    }
    .input {
        width: 8em !important;
    }
}
.fieldname {
    label {
        display: inline-block !important;
        width: 125px;
        height: 'inherit';
        color: #00000099 !important;
        font-size: 13px/16px;
    }
    > div {
        width: max-content !important;
        max-width: 100% !important;
    }
    height: 'inherit';
}

.required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: $required;
}
.pageName {
    font-size: 18px;
    color: $font-color-5;
    padding-top: 20px;
    margin-left: 5px;
    font-weight: bold;
}
.buildicon {
    background: #2185d0 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 44px !important;
    width: 44px !important;
    &::before {
        font-size: 17px;
        top: 11px;
        position: relative;
    }
}
.contractorinfo {
    box-shadow: 0px 1px 2px #22242626 !important;
    table {
        th {
            padding: 0.62857143em 0.78571429em !important;
        }
    }
}
.top {
    position: relative;
    top: 10px;
    color: $font-color-4;
    font: normal normal bold 16px/40px;
    letter-spacing: 0px;
    left: 8px;
}
.comdetail {
    display: flex;
    flex: 1;
    > div {
        width: 33%;
        div {
            padding-bottom: 15px;
        }
    }
    label {
        font: normal normal bold 13px/16px;
        letter-spacing: 0px;
        color: #00000099;
    }
    span {
        font: normal normal bold 14px/17px;
        letter-spacing: 0px;
        color: $font-color-4;
        text-transform: lowercase;
        padding-left: 5px;
    }
}
.rowLowPadding {
    padding-top: 0.5em !important;
}

.modelHeader {
    font-size: 18px !important;
    color: $font-color-4 !important;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
}
.mandatory {
    font-size: 12px;
    color: #000;
    line-height: 15px;
    font-style: italic;
}
.inputDisabled {
    opacity: 1 !important;
    label {
        display: inline-block !important;
        width: 125px;
        color: #00000099 !important;
        font-size: 13px/16px;
        opacity: 1 !important;
    }
    > div {
        width: auto !important;
    }
    input {
        background: #e7e3e3 !important;
    }
}
.contractorHeader {
    color: #3c3c3c !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-weight: 700 !important;
}
.fieldWrapper {
    display: flex !important;
    label {
        width: 9rem;
        min-width: 9rem;
        margin-top: 9px;
        color: #333333;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
    }
}

.transitionMsg {
    position: absolute !important;
    z-index: 1;
    width: 35% !important;
    margin: 0 auto !important;
    top: 70px;
    left: 0;
    right: 0;
    padding: 7px !important;
    i {
        //background: transparent !important;
        &::before {
            position: relative;
            top: 7px;
            font-size: 16px;
        }
    }
    span {
        span {
            position: relative;
            top: 7px;
        }
    }
}
.textAreafieldname {
    overflow-y: auto;
    resize: none;
    width: 179px;
    position: unset;
    textarea {
        padding: 0.67857143em 1em !important;
        height: 41px;
    }
    textarea + div {
        width: 50% !important;
    }
    label {
        display: inline-block !important;
        width: 125px;
        color: #00000099 !important;
        font-size: 13px/16px;
    }
    > div {
        width: auto !important;
    }
}

@media (max-width: 991px) {
    .fieldWrapper {
        padding-bottom: 20px;
    }
    .rowWrapper {
        flex-direction: column !important;
        padding-bottom: 10px;
    }
    .textAreafieldname {
        overflow-y: initial !important;
        textarea {
            width: 181px !important;
        }
    }
}

.container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15rem !important;
    margin-right: 15rem !important;
}
.innerPagecontainer {
    background-color: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15rem !important;
    margin-right: 15rem !important;
}

.form {
    height: 14rem;
}
