.exception {
    padding: 3px 8px;
    color: #009bda;
    border: 1px solid #009bda;
    border-radius: 3px;
    cursor: pointer;
}
.stopException {
    padding: 3px 8px;
    color: #9f3a38;
    border: 1px solid #9f3a38;
    border-radius: 3px;
    cursor: pointer
}
.notAllowed {
    padding: 3px 8px;
    color: #777272;
    border: 1px solid #777272;
    border-radius: 3px;
    cursor: not-allowed
}
.actionWidth {
    min-width: 140px;
}