// @import 'src/assets/styles/_variables.scss';

.updated input {
    border-color: currentColor;
    text-align: center !important;
}

.unupdated input {
    border-color: #db2828 !important;
    text-align: center !important;
}

.mainTable thead tr:first-child th:first-child {
    border-right: 1px solid #dededf !important;
}

.mainTable tbody tr td:first-child {
    border-right: 1px solid #dededf !important;
}

.ddDefault {
    height: fit-content;
}

.ddDefault .dropdown {
    overflow-y: auto !important ;
}

$error-color: #9f3a38;
$success-color: #568555;

.wrapper {
    //height: calc(100vh - 64px);
    margin-left: 20px !important;
    padding-left: 20px;
    padding-right: 20px;
}

.toastError {
    i::before {
        font-size: larger !important;
        color: $error-color !important;
    }
}

.toastSuccess {
    i::before {
        font-size: larger !important;
        color: $success-color !important;
    }
}

.headerContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.pageTitle {
    font-size: 18px !important;
    color: rgba(38, 38, 38, 1) !important;
    font-weight: bold !important;
}

.usFilters {
    // margin-top: 26px !important;
    margin-bottom: 3px !important;
}

.c1 {
    height: 100% !important;
    font-size: 13px !important;
}

.c2 {
    height: 100% !important;
    font-size: 16px !important;
}

.rl {
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 11px !important;
}

.spendTable {
    margin-bottom: 10px !important;
    table {
        thead {
            tr {
                td {
                    font-size: 13px !important;
                }
            }
        }
    }
}

.th {
    font-size: 13px !important;
    color: #000000cc !important;
}

// .ddDefault .dropdown {
//     overflow-y: auto !important ;
// }

.fillText {
    font-size: 13px;
    font-weight: bold;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #606060;
}

.container {
    margin-left: unset !important;
    margin-right: unset !important;
    p {
        text-align: left;
        font: Bold 12px/15px;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 0.5);
        margin-left: 35px;
    }

    .segment {
        background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
        border: 1px solid rgba(230, 230, 231, 1);
        border-radius: 4px;
        table {
            background: rgba(249, 250, 251, 1) 0% 0% no-repeat padding-box;
            border: 1px solid rgba(226, 226, 227, 1);
            border-radius: 4px 4px 0px 0px;
            thead {
                th {
                    background: rgba(249, 250, 251, 1) 0% 0% no-repeat
                        padding-box !important;
                    border: 1px solid rgba(226, 226, 227, 1) !important;
                    border-radius: 4px 4px 0px 0px !important;
                    text-align: left !important;
                    // font: Heavy 14px/24px !important;
                    letter-spacing: 0px !important;
                    color: rgba(0, 0, 0, 0.8) !important;
                }
            }
            tbody {
                td {
                    text-align: left;
                    // font: Regular 13px/24px;
                    letter-spacing: 0px;
                    color: rgba(0, 0, 0, 0.8);
                    h5 {
                        text-align: left;
                        font: Bold 14px/24px;
                        letter-spacing: 0px;
                        color: rgba(0, 0, 0, 0.8);
                    }
                }
            }
        }
    }
}

.notHeader {
    display: inline-block;
    margin: 0 0 0 !important;
    line-height: 22px !important;
    font-size: 18px;
}

.notIcon {
    background: rgba(167, 0, 181, 1) 0% 0% no-repeat padding-box;
    top: 109px;
    left: 21px;
    width: 22px;
    height: 26px;
}

.inDays {
    padding: 0.5em;
    background: rgba(247, 225, 207, 1) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font-weight: bolder;
}

.currency.ui {
    // color: rgba(167, 0, 181, 1);
    display: none;
}

.spendList {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #e7e3e3;
    padding: 10px 5px;
    background: #f9fafb;
    align-items: center;
}

.utilList {
    //visibility: hidden;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #e7e3e3;
    padding: 10px 5px;
    background: #f9fafb;
    align-items: center;
}


.utilListHide {
    visibility: hidden;
}
.tierListHide {
    display: none;
}


.normalContract {
    //visibility: hidden;
    display: flex;
    flex-wrap: wrap;
    // border: 1px solid #e7e3e3;
    // padding: 10px 5px;
    // background: #f9fafb;
    // align-items: center;
    
    // border: 1px solid red;
}

.contractFilters {
    display: flex;
    border: 1px solid #e7e3e3;
    background: #f9fafb;
    padding: 10px 5px;
    margin-left: 10px;
    // flex-wrap: wrap;
}

.filtersRow {
    display: flex;
    // border: 1px solid red;
}

.column {
    border: 1px solid green;
}
.lableFieldAlign {
    display: flex;
}


.verticalLine {
    width: 12px;
    margin-left: 5px;
    border-left: 3px solid #cccaca;
}

.dimmed {
    position: relative;
    content: " ";
    display: block;
    position: absolute;
    height: 100%;
    top: 77px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    .dimmedText {
        position: absolute;
        top: 35%;
        text-align: center;
        color: red;
        font-size: 18px;
        width: 100%;
        background: #fff;
        left: 0;
        font-weight: 600;
        padding: 15px 200px;
    }
  }
  

@media (max-width: 1090px) {
    .filtersRow {
        display: flex;
        flex-direction: column;
    }
    
}
