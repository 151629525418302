@import 'src/assets/styles/_variables.scss';
.form {
    :global {
        .field {
            width: 100%;
        }
    }
}
.passwordField {
    width: 100%;
}

.btnSignIn {
    width: 100%;
    background: #009bda !important;
    border-radius: 5px;
    color: $white !important;
    height: 54px;
    opacity: 1;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    padding-left: 10px;
}
.signDesc {
    text-align: left;
    font: normal normal normal 15px/18px;
    letter-spacing: 0px;
    color: $font-color-2;
    opacity: 1;
}
.loginrow {
    //padding-bottom: 0px !important;

    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background-color: #002857;
}
.loginContent {
    margin: auto;
    text-align: center;
    width: 92%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font: normal normal normal 18px/26px;
    span {
        font-size: 25px;
        line-height: 40px;
    }
}

span.header {
    color: #57bb47 !important;
    font-size: 3rem !important;
    padding: 0 0 30px 0 !important;
    line-height: 50px !important;
    display: block !important;
    margin: calc(2rem - .14286em) 0 1rem;
    margin-top: -.14285714em;
    font-family: Arial,Helvetica,sans-serif !important;
    font-weight: 700;
}
span.application {
    color: #002857 !important;
    font-size: 3rem !important;
    padding: 0 0 23px 0 !important;
    line-height: 50px !important;
    display: block !important;
    margin: calc(2rem - .14286em) 0 1rem;
    font-family: Arial,Helvetica,sans-serif !important;
    font-weight: 700;
    margin-top: -10px;
}
.loginImg {
    overflow: -moz-hidden-unscrollable;
    display: block;
    background-color: #fff;
    //opacity: 0.96;
    height: 100vh;
    color: $white;
    width: 61% !important;
    .content {
        color: $white;
    }
}

.fieldContainer {
    width: 100% !important;
    margin-bottom: 1em;
    text-align: left;
    :global {
        label {
            color: $font-color-1 !important;
            text-align: left;
            font: normal normal bold 13px/22px;
        }
    }
}
.checkboxWrapper {
    text-align: left;
    label {
        padding-left: 25px;
        font-size: 12px;
        line-height: 22px;
        color: #000;
        padding-top: 3px;
    }
}
.fieldContainerCHeckbox {
    display: inline;
    position: relative;
    top: 4px;
    div:nth-child(2) {
        display: inline-block;
        position: absolute !important;
        left: 9px;
        top: 18px;
        width: 192px;
    }
}

.btnContainer {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
        width: 191px;
        height: 54px;
        &:first-of-type {
            margin-right: 1em;
        }
    }
}

.fieldContainerPassword {
    position: relative;
    text-align: left;
}

.forgotpasswordContainer {
    padding-top: 15px;

    a {
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #001c71cc;
        opacity: 1;
    }
}
.signIn {
    text-align: left;
    width: 36% !important;
    margin-left: 20px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    padding: 0 20px !important;
    
    border-radius: 5px;
    // position: absolute !important;
    right: 23px;
    top: 50%;
    left: 0%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: max-content;
    form {
        padding: 0 25px;
        position: initial !important;
    }
}
.alignBox {
    left: 30px;
    background-color: #fff;
}
.gridAlign {
    margin: 0px !important;
    height: 100vh;
}
.eyeicon {
    color: lightgray;
    position: absolute;
    float: right;
    top: 35px;
    right: 4px;
    cursor: pointer;
}
.signinFooterContainer {
    width: 90%;
    margin: 0 auto;
    padding: 10px 0 0 0;
    margin-bottom: 7%;
    span {
        font-size: 25px;
        line-height: 17px;
        letter-spacing: 0px;
        color: #7f7878;
    }
}
.loginTitle {
    font-size: 35px;
    font-weight: bold;
    line-height: 42px;
    letter-spacing: 0px;
    color: #011c71;
    padding-bottom: 18px;
    margin: 12px 0px 30px 0px;
}

@media only screen and (max-width: 600px) {
    .loginrow {
        flex-direction: column !important;
    }
    .loginImg {
        display: none !important;
    }
    .signIn {
        width: 90% !important;
        margin: 0 auto;
    }
}
@media only screen and (max-width: 950px) {
    .forgotpasswordContainer {
        padding: 0 0 10px 0;
    }
    .btnContainer {
        flex-direction: column !important;
    }
}
.emploginBtn {
    margin-top: 8px !important;
    color: #fff !important;
    height: 54px !important;
    background: #011c71 !important;
    margin-bottom: 20px !important;
    max-height: 3.2em;
    min-width: max-content;
    font-size: 22px !important;
    width: 65%;
    span {
        position: relative;
        top: 8px;
    }
}
.extemploginBtn {
    margin-top: 20px !important;
    color: #fff !important;
    height: 54px !important;
    background: #1ba8e2 !important;
    margin-bottom: 10px !important;
    max-height: 3.2em;
    min-width: max-content;
    width: 75%;
    font-size: 16px !important;
    span {
        position: relative;
        top: 8px;
    }
}
.buildicon {
    opacity: 1;
    position: relative;
    top: -8px;
    &::before {
        color: #fff;
        font-size: 17px !important;
        top: 11px !important;
        position: relative !important;
    }
}
.loginFooter {
    //position: relative;
    top: 63px;
}
.devider {
    margin-top: 25px !important;
    span {
        color: #333333dd;
        font-size: 14px;
        line-height: 17px;
        position: relative;
        top: -16px;
        background: #fff;
        text-transform: none;
        border: 13px solid transparent;
        border-top: 0px;
    }
}
.footerImg {
    display: inline-flex;
    flex-direction: row;
    padding: 25px 0 10px 0;

    padding-top: 25px;
    img {
        margin: 0 auto;
    }
}

.gridBody {
    margin-left: 0px !important;
}
.toastMessage {
    text-align: left;
    margin-bottom: 10px;
    i.close {
        &::before {
            color: #912d2b !important;
        }
    }
    :global {
        .ui.error.message > .icon.close {
            &::before {
                color: #912d2b !important;
            }
        }
    }
}
.forgotPassDesc {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #333333dd;
    text-align: left;
    padding-bottom: 20px;
}
.forgotPassFooterContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-bottom: 7rem;
    padding-top: 2rem;
    font-size: 12px;
}
.email {
    font-size: 12px;
    letter-spacing: 0px;
    color: #012957;
    font-weight: bold;
}
.forgotPassbtnContainer {
    display: flex;
    justify-content: flex-end;
    button {
        height: auto;
    }
}

.btnSignIn {
    width: 60%;
    background: #009bda !important;
    border-radius: 5px;
    color: $white !important;
    height: 54px;
    opacity: 1;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
    padding-left: 10px;
    padding-top:15px;
    padding-bottom:15px;
}
.bookmarkLabel{
    color: $white !important;
    font-size:  16px !important;
    font-weight:bold
}