.wrapper {
    margin-top: 5px;
    table {
        tr {
            th {
                border-left: none !important;
            }
            td {
                border-left: none !important;
            }
        }
        i {
            background: transparent;
        }
    }
}

.firstName {
    width: 8rem !important;
}

.lastName {
    width: 8rem !important;
}

.emailAddress {
    width: 13rem !important;
}

.userType {
    width: 15rem !important;
}
.entity {
    width: 15rem !important;
}

.alias {
    width: 5rem !important;
}

.action {
    width: 13rem !important;
    padding-left: 25px !important;
}
.rowData {
    font-size: 14px !important;
    line-height: 24px;
    color: #000 !important;
}
.announcement {
    width: 20rem !important;
}

.announcementHeader {
    width: 20rem !important;
}
.announcementDescriptionHeader {
    width: 60rem !important;
}

.expiredParent {
    background-color: #d0191908;
    font-weight: bold;
    color: #d01919;
}

.validParent {
    background-color: #fff;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
}
