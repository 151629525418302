.leftContent{
    margin: 8px;
    border-right:2px solid #edeaea;
    i::before{
        font-size: 20px;
    }
    span.main{
        float: left;
        position: relative;
        width: 100%;
        text-align: left;
        span.head{
            position: absolute;
            top: 0px;
            left: 25px;
            font-size: 15px;
            font-weight: 600;
        }
    }
    
}

.rightContent{
    margin: 8px;
    i::before{
        font-size: 17px;
    }
    span.main{
        float: left;
        position: relative;
        width: 100%;
        text-align: left;
        span.head{
            position: absolute;
            top: 0px;
            left: 25px;
            font-size: 13px;
            font-weight: 600;
        }
    }
}

.ui.radio.checkbox .box, .ui.radio.checkbox label {
    padding-left: 20px;
}

.leftDropDown{
    color: #000000A5;
    float: left;
    margin-top: 25px;
    width: 96%;
    .select{
        font-size: 14px;
        color: #606060;
        min-height: 20px !important;
        padding: 6px !important;
        margin-bottom: 5px;
        width: 100%;
        i {
            padding: 0 !important;
            margin-top: -3px !important;
            margin-right: -5px !important;
        }
    }
}
.rightDropDown{
    color: #000000A5;
    float: left;
    margin-top: 2px;
    width: 96%;
    .select{
        font-size: 14px;
        color: #606060;
        min-height: 20px !important;
        padding: 6px !important;
        margin-bottom: 2px;
        width: 100%;
        i {
            padding: 0 !important;
            margin-top: -3px !important;
            margin-right: -5px !important;
        }
    }
}