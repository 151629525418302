@import 'src/assets/styles/_variables.scss';

.nav {
    width: 100%;
    padding-bottom: 3%;
    display: flex;
}

.required:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: $required;
}

.column {
    width: 200%;
}

@media (max-width: 991px) {
    .fieldWrapper {
        padding-bottom: 20px;
    }
    .rowWrapper {
        flex-direction: column !important;
        padding-bottom: 10px;
    }
    .textAreafieldname {
        overflow-y: initial !important;
        textarea {
            width: 181px !important;
        }
    }
}

.container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15rem !important;
    margin-right: 15rem !important;
}
.innerPagecontainer {
    background-color: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15rem !important;
    margin-right: 15rem !important;
}
