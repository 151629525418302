@import 'src/assets/styles/_variables.scss';

.wrapperPrimeDetails {
    display: flex;
    flex-direction: row;
    // padding-left: 20px;
    // padding-right: 20px;

    
   .segment {
       border: 0 !important;
   }
   
    
}

.buildicon {
    background: #2185d0 !important;
    opacity: 1;
    height: 44px !important;
    width: 44px !important;
    &::before {
        font-size: 17px !important;
        top: 11px !important;
        position: relative !important;
    }
}
.top {
    position: relative;
    top: 10px;
    color: $font-color-4;
    font: normal normal bold 16px/40px;
    letter-spacing: 0px;
    left: 8px;
    span{
        border-radius: 4px;
        height: 28px;
        width: fit-content;
        padding: 5px 8px;
        margin-left: 10px;
        font-size: 13px;
        line-height: 24px;
        font-weight: bold;
        position: relative;
        top: -2px;
    }
    .active{ 
        background: #C1F2F89D !important;
    }
    .inactive{ 
        background: #f7cfcf !important;
    }
}
.dateAdded {
    display: flex;
    flex-direction: row;
    color: #333333DE;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    text-align: right !important;
    span {
        display: block;
    }
}
