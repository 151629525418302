$normal-font-size: 16px;
$small-font-size: 13px;

$default-text-color: #333333DE;
$link-text-color: #6a42d3;
$required: #db2828;

$white: #fff;
$blue: #148ffd;
$test-env: #EA8127;
$headerBG: #002857;
$headerFont-color: #000000;
$loginImgBG: #002857;
$font-color-1: #333333;
$font-color-2: #333333;
$font-color-3: #001C71;
$font-color-4: #000000;
$font-color-5: #262626;
$font-color-6: #276f86;

$background-color-1: #f7f7f7;
$background-color-2: #009bda;

$border-color-1: #009bd9; 


$border1: #DDDDDD;

$blue-100: #ebf3ff;
$blue-200: #cce0ff;
$blue-300: #99c2ff;
$blue-400: #66a3ff;
$blue-500: #3385ff;
$blue-600: #3385ff;
$blue-700: #075fe3;
$blue-800: #0e58c6;
$blue-900: #1551aa;

$green-100: #effbf8;
$green-200: #d7f5ee;
$green-300: #afebdd;
$green-400: #87e2cc;
$green-500: #5ed8bb;
$green-600: #36ceaa;
$green-700: #3cc6a5;
$green-800: #3cb094;
$green-900: #3b9b84;

$gray-100: #f3f7fb;
$gray-200: #dfe6f1;
$gray-300: #bfcada;
$gray-400: #a5b1c4;
$gray-500: #95a8bb;
$gray-600: #6f88a2;
$gray-700: #3d6084;
$gray-800: #224272;
$gray-900: #2d3848;

$orange-100: #fff7ee;
$orange-200: #ffebd5;
$orange-300: #ffd8aa;
$orange-400: #ffc480;
$orange-500: #ffb155;
$orange-600: #ff9d2b;
$orange-700: #df8b2a;
$orange-800: #be7a2a;
$orange-900: #9e6829;

$red-100: #fff1f1;
$red-200: #ffdcdc;
$red-300: #ffb8b8;
$red-400: #ff9595;
$red-500: #ff7171;
$red-600: #ff4e4e;
$red-700: #de4747;
$red-800: #bd4040;
$red-900: #9c3939;

/* CalendarPicker Variables */
$base-cell-width: 14.2857142857%;
$picker-max-width: 430px;
$expanded-picker-max-width: 570px;
