.wrapper {
    margin-top: 5px;
    table {
        tr {
            th {
                border-left: none !important;
            }
            td {
                border-left: none !important;
            }
        }
        i {
            background: transparent;
        }
    }
}

.firstName {
    width: 8rem !important;
}

.lastName {
    width: 8rem !important;
}

.emailAddress {
    width: 13rem !important;
}

.userType {
    width: 15rem !important;
}
.entity{
    width: 15rem !important;
}

.alias {
    width: 5rem !important;
}

.action {
    width: 13rem !important;
    padding-left: 25px !important;
}



.filters {
    //margin-top: 0px !important;
    i {
        background: transparent !important;
        color: #666 !important;
    }
    label {
        color: #5b5b5b;
        font-size: 13px;
        font-weight: bold;
        margin: 0 0 0.28571429rem 0;
    }
    .filterCheckbox {
        margin-top: 31px;
    }
    button.primary {
        background: #009bd9 !important;
        color: #fff !important;
    }
    // div:nth-child(2) {
    //     padding: 0 !important;
    // }
  }
  .tooltip {
    color: #9d4d4d !important;
    border-color: #9d4d4d !important;
    position: relative !important;
    // left: 25% !important;
    // right: 25% !important;
  }
  
.filterSection {
    color: #5b5b5b;
    font-size: 13px;
    font-weight: bold;
    margin: 0 0 0.28571429rem 0;
}