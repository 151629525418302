@import 'src/assets/styles/_variables.scss';
.wrapper {
    //height: calc(100vh - 144px);
    display: flex;
    flex-direction: row;
    background-color: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;

    h1.header {
        padding-top: 4em !important;
        font-size: 4em;
    }
}
.pageName {
    font-size: 18px;
    padding-top: 20px;
    margin-left: 5px;
    font-weight: 700;
    color: #262626;
}
.textCenter {
    text-align: center !important;
}
.borderlefttNo {
    border-left: 0px !important;
    border-right: none !important;
    text-align: center !important;
}
.tooltip {
    display: inline-block;
    top: 4px;
    left: 4px;
}
.spendSummary {
    box-shadow: 0px 1px 2px #22242626 !important;
    table {
        th {
            padding: 0.62857143em 0.78571429em !important;
        }
    }
}
i {
    font-size: 1em;
    background-color: #848080;
    border-radius: 100%;
    width: 1em;
    color: #fff;
    font-weight: 300;
    &::before {
        font-size: 9px;
        position: relative;
        top: -4px;
    }
}
.summarytooltip {
    width: 10rem !important;
    background-color: #535353 !important;
    &::before{
        background-color: #535353 !important;
    }
}
.td {
    text-align: center !important;
    font-size: 14px !important;
    line-height: 24px;
    color: #000 !important;
    opacity: 0.87;
}
.tdRed {
  text-align: center !important;
  font-size: 14px !important;
  line-height: 24px;
  color: #D01919 !important;
}
.greenColor {
    color: #2DAD4E !important;
}
.contractorNam {
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    width: 40rem !important;
}
.summaryHeader {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.8);
    //line-height: 22px;
    
}
.total {
font-weight: bold;
color: #000;
}
.infoIcon {
    color: #848080 !important;
    cursor: pointer;
}
