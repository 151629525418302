
$error-color: #9f3a38;
$success-color: #568555;

.toastError {
    i::before {
        font-size: larger !important;
        color: $error-color !important;
    }
}

.toastSuccess {
    i::before {
        font-size: larger !important;
        color: $success-color !important;
    }
}
