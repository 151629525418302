.wrapper {
    min-height: calc(100vh - 64px);
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #f7f7f7;
    padding-left : 20px;
    padding-right : 20px;

    h1.header {
        padding-top: 4em !important;
        font-size: 4em;
    }
}

.dFlex {
    display: flex
}

.fCol {
    flex-direction: column;
}

.fRow {
    flex-direction: row;
}

.j {
    .center {
        justify-content: center;
    }
}

.a {
    .center {
        align-items: center;
    }
}