@import 'src/assets/styles/_variables.scss';
.wrapper {
    //height: calc(100vh - 144px);
    display: flex;
    flex-direction: row;
    background-color: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;

    
    h1.header {
        padding-top: 4em !important;
        font-size: 4em;
    }
    table {
        tr {
            th {
                border-left: none !important;
            }
            td {
                border-left: none !important;
            }
        }
        i {
            background: transparent;
        }
    }
    button {
        font: 14px;
        font-weight: normal;
        i {
            color: #009bda !important;
        }
    }
    i {
        &::before {
            font-size: 12px;
            top: 0px;
        }
    }
}
button {
    i {
        background: transparent !important;
        color: #009bda !important;
    }
}
.address,
.subcontractor{
  width: 27rem !important;
}
.city{
  width: 10rem !important;
}
.state{
  width: 5rem !important;
}
.status{
  width: 8rem !important;
}
.action{
  width: 5rem !important;
}
.select{
  width: 8rem !important;
}
.verificationcell {
    width: 17rem !important;
    white-space: normal !important;
}
.pageName {
    font-size: 18px;
    color: $font-color-5;
    padding-top: 20px;
    margin-left: 5px;
    font-weight: bold;
}
.activeContract {
    width: 12px;
    height: 12px;
    background: #2c662d;
    border-radius: 100%;
    display: inline-block;
    margin-right: 3px;
}
.inactiveContract {
    width: 12px;
    height: 12px;
    background: #9f3a38;
    border-radius: 100%;
    display: inline-block;
    margin-right: 3px;
}
.tooltipi {
    width: 1em !important;
    background: #848080 !important;
    position: relative;
    top: 3px !important;
    left: 4px !important;
    &::before {
        top: -4px !important;
        font-size: 9px !important;
    }
}
.label {
    font-size: 13px;
    color: #000000a5;
    span {
        display: inline-block;
        margin-right: 10px;
    }
}
.colorRowTop {
    padding-top: 1rem !important;
    padding-bottom: 0.5rem !important;
}
.colorRow {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.labelcolor {
    div {
        width: 17px !important;
        height: 17px !important;
        border-radius: 0 !important;
    }
}
.labelBox {
    border: 0.5px solid #999999 !important;
}

.backBtn, .enterSubBtn{
    margin-left: 15px !important;
    font-size: 14px !important;
    line-height: 17px !important;
    background-color: $white !important;
    border: 1px solid $border-color-1 !important;
    color: $background-color-2 !important;
    border-radius: 4px;
}
.addSelBtnDisable {
    //background-color: #D1CFCF !important;
    //margin-left: 8px !important;
    //font-weight: 700 !important;
    //font-size: 14px !important;
    background-color: #D1CFCF !important;
    margin-left: 15px !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    height: 38px;
    color: $white !important;
    border-radius: 4px;
    opacity: 1 !important;
 }
 .addSelBtnEnable {
    //margin-left: 8px !important;
    //font-weight: 700 !important;
    //font-size: 14px !important;
 }
 .addSelBtnEnable {
    margin-left: 15px !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    height: 38px;
    //border: 1px solid $border-color-1 !important;
    color: $white !important;
    border-radius: 4px;
 }

.filters {
    //margin-top: 0px !important;
    i {
        background: transparent !important;
        color: #666 !important;
    }
    label {
        color: #5b5b5b;
        font-size: 13px;
        font-weight: bold;
        margin: 0 0 .28571429rem 0;
    }
    .filterCheckbox {
        margin-top: 31px;
    }
    button.primary {
        background: #009bd9 !important;
        color: #fff !important;
    }
    // div:nth-child(2) {
    //     padding: 0 !important;
    // }
}
.transitionMsg {
    position: absolute !important;
    z-index: 1;
    width: 35% !important;
    margin: 0 auto !important;
    left: 0;
    right: 0;
    padding: 7px !important;
    i {
        //background: transparent !important;
        &::before {
            position: relative;
            top: 7px;
            font-size: 16px;
        }
    }
    span {
        span {
            position: relative;
            top: 7px;
        }
    }
}
.closeIcon {
    top: 6px !important;
    background: transparent !important;
    &::before {
        color: #568555;
    }
}
.rowData {
    font-size: 14px !important;
    line-height: 24px;
    color: #000 !important;
}
.addSelBtnDisable {
    background: #D1CFCF !important;
    opacity: 1 !important;
    font-weight: 400 !important;
}
.addSelBtnEnable {
    margin-left: 4px !important;
}
.instructionContent {
    line-height: 20px !important;
    font-size: 12px !important;
    color: #276F86 !important;
    opacity: 1 !important;

  }
.topButtons {
    
}

.filterSection {
  color: #5b5b5b;
  font-size: 13px;
  font-weight: bold;
  margin: 0 0 0.28571429rem 0;
}
