@import 'src/assets/styles/_variables.scss';
.wrapper {
    // height: calc(100vh - 144px);
    display: flex;
    flex-direction: row;
    //background-color: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;

    h1.header {
        padding-top: 4em !important;
        font-size: 4em;
    }

    button {
        // background-color: #fff !important;
        // border: 1px solid #009BD9 !important;
        // color: #009BDA !important;
        // font: 14px;
        // font-weight: normal;
        i {
            color: #009bda !important;
        }
    }
    i {
        &::before {
            font-size: 12px;
            top: 0px;
        }
    }
    table {
        tr {
            th {
                border-left: none !important;
            }
            td {
                border-left: none !important;
            }
        }
        i {
            background: transparent;
        }
    }
}
.reason {
    width: 15rem !important;
}
.subcontractor {
    width: 14rem !important;
}
.city {
    width: 10rem !important;
}
.state {
    width: 15rem !important;
}
.status {
    width: 8rem !important;
}
.action {
    width: 5rem !important;
}
.verificationcell {
    width: 17rem !important;
    white-space: normal !important;
}
.pageName {
    font-size: 18px;
    color: $font-color-5;
    padding-top: 20px;
    margin-left: 5px;
    font-weight: bold;
}
.activeContract {
    width: 12px;
    height: 12px;
    background: #2c662d;
    border-radius: 100%;
    display: inline-block;
    margin-right: 3px;
}
.inactiveContract {
    width: 12px;
    height: 12px;
    background: #9f3a38;
    border-radius: 100%;
    display: inline-block;
    margin-right: 3px;
}
.label {
    font-size: 13px;
    color: #000000a5;
    span {
        display: inline-block;
        margin-right: 10px;
    }
}
.colorRowTop {
    padding-top: 1rem !important;
    padding-bottom: 0.5rem !important;
}
.colorRow {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.labelcolor {
    div {
        width: 17px !important;
        height: 17px !important;
        border-radius: 0 !important;
    }
}
.labelBox {
    border: 0.5px solid #999999 !important;
}
.transitionMsg {
    position: absolute !important;
    z-index: 1;
    width: 30% !important;
    margin: 0 auto !important;
    top: 70px;
    left: 0;
    right: 0;
    padding: 7px !important;
    color: #1e561f !important;
    font-weight: 700;
    font-size: 14px !important;
    line-height: 17px !important;
    i {
        //background: transparent !important;
        &::before {
            position: relative;
            top: 7px;
            font-size: 16px;
        }
    }
    span {
        padding-left: 5px;
        span {
            position: relative;
            top: 7px;
        }
    }
}
.dataGrid {
    i {
        background: transparent !important;
        color: #666;
    }
}
// .tooltipi {
//   background: #848080 !important;
// }
.rowData {
    font-size: 14px !important;
    line-height: 24px;
    color: #000 !important;
    width: 20% !important;
}
.summarytooltip {
    width: 10rem !important;
    background-color: #535353 !important;
    &::before {
        background-color: #535353 !important;
    }
}



.dimmed {
    position: relative;
    content: " ";
    display: block;
    position: absolute;
    height: 100%;
    top: 77px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    z-index: 99;
    .dimmedText {
        position: absolute;
        top: 35%;
        text-align: center;
        color: red;
        font-size: 18px;
        width: 100%;
        background: #fff;
        left: 0;
        font-weight: 600;
        padding: 15px 200px;
        z-index: 99;
    }
  }
