.wrapper {
    margin-top: 25px;
    table {
        tr {
            th {
                border-left: none !important;
            }
            td {
                border-left: none !important;
            }
        }
        i {
            background: transparent;
        }
    }
}

.subcontractor {
    width: 15rem !important;
}

.von {
    width: 8rem !important;
}
.prime {
    width: 15rem !important;
}
.edit {
    width: 5rem !important;
}
.inputLabel{
    margin-left: 20px !important;
}

.inputSection  {
    input{
        margin-left: 15px !important;
        width: 18rem !important;
    }
}

.inputSectionVon {
    input{
        margin-left: 15px !important;
        width: 18rem !important;
    }
    
}
div.inputSectionSelect  {
    margin-left: 10px !important;
    width: 18rem  !important;
}



@media (max-width: 1500px) {
    .inputSection  {
        input{
            margin-left: 8px !important;
            width: 13rem  !important;
        }
    }
    div.inputSectionSelect  {
        min-width: 10rem !important;
        margin-left: 8px !important;
        width: 10rem  !important;
    }
    .inputSectionVon {
        input{
            margin-left: 8px !important;
            width: 16rem !important;
        }
    }
    
    .inputLabel {
        margin-left: 15px !important;
    }

}

@media (max-width: 1280px) {
    .inputSection  {
        input{
            margin-left: 5px !important;
        }
    }
    div.inputSectionSelect  {
        min-width: 8rem !important;
        margin-left: 5px !important;
        width: 8rem  !important;
    }
    .inputSectionVon {
        input{
            margin-left: 5px !important;
            width: 16rem !important;
        }
    }
    
    .inputLabel {
        margin-left: 10px !important;
    }
}
