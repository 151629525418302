@import 'src/assets/styles/_variables.scss';
.wrapper {
    // height: calc(100vh - 144px);
    display: flex;
    flex-direction: row;
    //background-color: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;

    h1.header {
        padding-top: 4em !important;
        font-size: 4em;
    }
    table {
        tr {
            th {
                border-left: none !important;
            }
            td {@import 'src/assets/styles/_variables.scss';
.wrapper {
  // height: calc(100vh - 144px);
  display: flex;
  flex-direction: row;
  //background-color: #f7f7f7;
  padding-left : 20px;
  padding-right : 20px;

  h1.header {
      padding-top: 4em !important;
      font-size: 4em;
  }
  
  button {
    // background-color: #fff !important;
    // border: 1px solid #009BD9 !important;
    // color: #009BDA !important;
    // font: 14px;
    // font-weight: normal;
    i {
      color: #009BDA !important;
      
    }
  }
  i {
    &::before {
      font-size: 12px;
      top: 0px;
    }
  }
  table {
    tr {
      th {
        border-left: none !important;
      }
      td {
        border-left: none !important;
      }
      
    }
    i {
      background: transparent;
    }
  }
}
.reason{
  width: 15rem !important;
}
.subcontractor{
  width: 14rem !important;
}
.city{
  width: 10rem !important;
}
.state{
  width: 15rem !important;
}
.status{
  width: 8rem !important;
}
.action{
  width: 5rem !important;
}
.verificationcell {
  width: 17rem !important;
  white-space: normal !important;
}
.pageName {
  font-size: 18px;
  color: $font-color-5;
  padding-top: 20px;
  margin-left: 5px;
  font-weight: bold;
}
.activeContract {
  width: 12px;
  height: 12px;
  background: #2C662D;
  border-radius: 100%;
  display: inline-block;
  margin-right: 3px;
}
.inactiveContract {
  width: 12px;
  height: 12px;
  background: #9F3A38;
  border-radius: 100%;
  display: inline-block;
  margin-right: 3px;
}
.label {
  font-size: 13px;
  color: #000000A5;
  span {
    display: inline-block;
    margin-right: 10px;
  }
}
.colorRowTop {
  padding-top: 1rem !important;
  padding-bottom: 0.5rem !important;
}
.colorRow {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.labelcolor {
  div{
    width: 17px !important;
    height: 17px !important;
    border-radius: 0 !important;
  }
}
.labelBox {
    border: 0.5px solid #999999 !important;
}
.transitionMsg {
  position: absolute !important;
  z-index: 1;
  width: 30% !important;
  margin: 0 auto !important;
  top: 70px;
  left: 0;
  right: 0;
  padding: 7px !important;
  color: #1E561F !important;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 17px !important;
  i {
      //background: transparent !important;
      &::before {
          position: relative;
          top: 7px;
          font-size: 16px;
      }
  }
  span {
      padding-left: 5px;
      span {
          position: relative;
          top: 7px;
      }
  }
}
.dataGrid {
  
  
  i {
    background: transparent !important;
    color: #666;
  }  
}
// .tooltipi {
//   background: #848080 !important;
// }
.rowData {
  font-size: 14px !important;
  line-height: 24px;
  color: #000 !important;
  width: 20% !important;
}
.summarytooltip {
  width: 10rem !important;
  background-color: #535353 !important;
  &::before{
      background-color: #535353 !important;
  }
}
                border-left: none !important;
            }
        }
    }
}
.pageName {
    font-size: 18px;
    color: $font-color-5;
    padding-top: 20px;
    margin-left: 5px;
    font-weight: bold;
}
.activeContract {
    width: 12px;
    height: 12px;
    background: #2c662d;
    border-radius: 100%;
    display: inline-block;
    margin-right: 3px;
}
.inactiveContract {
    width: 12px;
    height: 12px;
    background: #9f3a38;
    border-radius: 100%;
    display: inline-block;
    margin-right: 3px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // height: auto;
    align-items: center;
    width: 100%;
}

.modalRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.modalCol {
    flex-grow: 1;
    padding: 0px;
}

.red {
    color: #d01919 !important;
}

i {
    background: none;
    color: #565656;
    &::before {
        font-size: 13px;
        position: relative;
        top: 0;
    }
}

.modalHeader {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px !important;
}

.tooltip {
    color: #9d4d4d !important;
    border-color: #9d4d4d !important;
    position: absolute !important;
    left: 25% !important;
    right: 25% !important;
}

.tooltipErrorDesc {
    color: #9d4d4d !important;
    border-color: #9d4d4d !important;
    position: absolute !important;
    left: 25% !important;
    right: 25% !important;
    // position: absolute !important;
    transform: translate(-50%, 8%);
    margin: 0 auto;
    // left: 50% !important;
    margin-top: 5px !important;
}
.notHeader {
    display: inline-block;
    margin: 0 0 0 !important;
    line-height: 22px !important;
    font-size: 18px;
}

.notIcon {
    background: rgba(167, 0, 181, 1) 0% 0% no-repeat padding-box;
    top: 109px;
    left: 21px;
    width: 22px;
    height: 26px;
}

.inDays {
    padding: 0.5em;
    background: rgba(247, 225, 207, 1) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font-weight: bolder;
}

$error-color: #9f3a38;
$success-color: #568555;

.toastError {
    i::before {
        font-size: larger !important;
        color: $error-color !important;
    }
}

.toastSuccess {
    i::before {
        font-size: larger !important;
        color: $success-color !important;
    }
}

.pageTitle {
    font-size: 18px !important;
    color: rgba(38, 38, 38, 1) !important;
    font-weight: bold !important;
}

.contractsTable {
    table {
        thead {
            // font: Heavy 13px #000000cc !important;
            tr {
                td {
                    font-size: 13px !important;
                    color: #000000cc !important;
                }
            }
            th {
                color: rgba(0, 0, 0, 0.8) !important;
            }
        }
    }
}
.descriptionWidth {
    width: 15rem;
}

.headerRow {
    display: flex;
    flex-direction: row;
    align-items: left;
    // justify-content: space-between !important;
    margin-left: 20px;
    margin-bottom: -3px;
    margin-right: 10px;
}

.reportFiltersWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    padding-top: 20px;
    padding-bottom: 20px;
}


@import 'src/assets/styles/_variables.scss';
.wrapper {
  // height: calc(100vh - 144px);
  display: flex;
  flex-direction: row;
  //background-color: #f7f7f7;
  padding-left : 20px;
  padding-right : 20px;

  h1.header {
      padding-top: 4em !important;
      font-size: 4em;
  }
  
  button {
    // background-color: #fff !important;
    // border: 1px solid #009BD9 !important;
    // color: #009BDA !important;
    // font: 14px;
    // font-weight: normal;
    i {
      color: #009BDA !important;
      
    }
  }
  i {
    &::before {
      font-size: 12px;
      top: 0px;
    }
  }
  table {
    tr {
      th {
        border-left: none !important;
      }
      td {
        border-left: none !important;
      }
      
    }
    i {
      background: transparent;
    }
  }
}
.reason{
  width: 15rem !important;
}
.subcontractor{
  width: 14rem !important;
}
.city{
  width: 10rem !important;
}
.state{
  width: 15rem !important;
}
.status{
  width: 8rem !important;
}
.action{
  width: 5rem !important;
}
.verificationcell {
  width: 17rem !important;
  white-space: normal !important;
}
.pageName {
  font-size: 18px;
  color: $font-color-5;
  padding-top: 20px;
  margin-left: 5px;
  font-weight: bold;
}
.activeContract {
  width: 12px;
  height: 12px;
  background: #2C662D;
  border-radius: 100%;
  display: inline-block;
  margin-right: 3px;
}
.inactiveContract {
  width: 12px;
  height: 12px;
  background: #9F3A38;
  border-radius: 100%;
  display: inline-block;
  margin-right: 3px;
}
.label {
  font-size: 13px;
  color: #000000A5;
  span {
    display: inline-block;
    margin-right: 10px;
  }
}
.colorRowTop {
  padding-top: 1rem !important;
  padding-bottom: 0.5rem !important;
}
.colorRow {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.labelcolor {
  div{
    width: 17px !important;
    height: 17px !important;
    border-radius: 0 !important;
  }
}
.labelBox {
    border: 0.5px solid #999999 !important;
}
.transitionMsg {
  position: absolute !important;
  z-index: 1;
  width: 30% !important;
  margin: 0 auto !important;
  top: 70px;
  left: 0;
  right: 0;
  padding: 7px !important;
  color: #1E561F !important;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 17px !important;
  i {
      //background: transparent !important;
      &::before {
          position: relative;
          top: 7px;
          font-size: 16px;
      }
  }
  span {
      padding-left: 5px;
      span {
          position: relative;
          top: 7px;
      }
  }
}
.dataGrid {
  
  
  i {
    background: transparent !important;
    color: #666;
  }  
}
// .tooltipi {
//   background: #848080 !important;
// }
.rowData {
  font-size: 14px !important;
  line-height: 24px;
  color: #000 !important;
  width: 20% !important;
}
.summarytooltip {
  width: 10rem !important;
  background-color: #535353 !important;
  &::before{
      background-color: #535353 !important;
  }
}


.filters {
  //margin-top: 0px !important;
  i {
      background: transparent !important;
      color: #666 !important;
  }
  label {
      color: #5b5b5b;
      font-size: 13px;
      font-weight: bold;
      margin: 0 0 0.28571429rem 0;
  }
  .filterCheckbox {
      margin-top: 31px;
  }
  button.primary {
      background: #009bd9 !important;
      color: #fff !important;
  }
  // div:nth-child(2) {
  //     padding: 0 !important;
  // }
}
.tooltip {
  color: #9d4d4d !important;
  border-color: #9d4d4d !important;
  position: relative !important;
  // left: 25% !important;
  // right: 25% !important;
}

.filterSection {
  color: #5b5b5b;
  font-size: 13px;
  font-weight: bold;
  margin: 0 0 0.28571429rem 0;
}