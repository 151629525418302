@import 'src/assets/styles/_variables.scss';

.contentWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
}

.wrapper.segment {
    border: none;
    height: 100vh;
    border-radius: 0;
}

.sidebar {
    width: calc(100vw - 260px);
}
.wrapper {
    //padding: 20px;
    background-color: #f7f7f7;
  }
.brand {
    padding: 0 !important;
    width: calc(100% - 130px) !important;

    img {
        height: 40px;
    }
}
.wrapper {
   // padding: 20px;
    background-color: #f7f7f7;
  }
  
  .loader {
    width: 100vw;
    height: 100vh;
  }
  .menu {
      margin-top: 0 !important;
  }
.container {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0px;
    color: $headerFont-color;    
    opacity: 1;
    width: 1440px !important;
    margin-left: 2em !important;
    label {
        border-right: 1px solid $border1;
        padding-right: 10px;
        padding-top: 9px;
    }
    a {
        padding: 0;
        color: #585858 !important;
        font-size: 13px !important;
        line-height: 16px !important;
    }
    a.active {
        color: #011C71 !important;
    }
}