// .notification {
//   margin: 0 auto !important;
//   position: absolute !important;
//   left: 0;
//   right: 0;
//   top: 13px;
//   width: 98%;
//   z-index: 1;
//   p{
//     display: inline-block;
//   }

//   :global {

//     i.warning {
//       position: relative;
//       top: -2px !important;
//       color: #b35213 !important
//     }
//   }

// }
.notification {
    z-index: 1;
    margin: 0 auto !important;
    position: relative !important;
    left: 0;
    right: 0;
    width: 100%;
}
.warning {
    margin: 0 auto !important;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 13px;
    width: 98%;
    z-index: 1;
    p {
        display: inline-block;
    }
    :global {
        i.warning {
            position: relative;
            top: -2px !important;
            color: #b35213 !important;
        }

        .ui.message > .icon.close {
            top: 4px !important;
            &::before {
                color: #912d2b !important;
            }
        }

        // i.close {
        //   position: relative;
        //   top: -2px !important;
        //   color: #b35213 !important;
        //   &::before {
        //     color: #912d2b !important;
        // }
        // }
    }
}
