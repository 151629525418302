@import 'src/assets/styles/_variables.scss';
.wrapper {
  display: flex;
  flex-direction: row;
  padding-right : 20px;
}
.pageTitle {
  font-size: 18px !important;
  color: rgba(38, 38, 38, 1) !important;
  font-weight: bold !important;
}

.useFilters {
  display: 'flex';
  padding-left:'30px';
  justify-content: 'left';
  margin-bottom: 3px !important;
}

.lableFieldAlign {
  display: flex;
}

.select{
  color: #606060;
  width: 15%;  
}
.text{
  color: #606060;
  width: 15%;  
}