@import 'src/assets/styles/_variables.scss';

.disableFunctionalityText {
    text-align: center;
    color: red;
    font-size: 18px;
    // width: 100%;
}

.disableFunctionality{
    width:'100%'
}
