
.rowData {
    font-size: 14px !important;
    line-height: 24px;
    color: #000 !important;
    min-width: 150px !important;
    white-space: pre;
}

.contact {
    min-width: 110px !important;
}

.header {
    min-width: 110px !important;
}

.socal {
    min-width: 140px !important;
}

.vendorId {
    min-width: 80px !important;
}

.columnBorderRight {
    border-right: 1px solid #e3e4e6 !important;
}



