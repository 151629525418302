@import '~normalize.css';
@import '~semantic-ui-css/semantic.min.css';
@import '~shared-it-appmod-ui/dist/css/styles.min.css';
body {
    background-color: hsl(0, 0%, 97%);
    overflow: auto;
}

/*
  contractor info update form
  */
.contractor-update_wrapper__59m5f {
    .error {
        .pointing {
            float: right;
            margin-right: 24px;
        }
    }
}
.subcontractor-create_wrapperCreatesubContractor__3PF0I {
    i.close {
        background: transparent !important;
        &::before {
            color: #912d2b !important;
            top: 4px !important;
            font-weight: 700;
            font-size: 13px !important;
        }
    }
    .subcontractor-create_disableDatepicker__1-c2D {
        i.close {
            background: transparent !important;
            &::before {
                color: initial;
                top: 50% !important;
            }
        }
    }
    
    
}
i.close {
    background: transparent !important;
    &::before {
        color: #568555;
        top: 4px !important;
    }
}


.error {
    position: relative;
    .pointing {
        position: absolute !important;
        top: 100%;
        left: 50%;
        width: fit-content;
        transform: translate(-50%, 8%);
        z-index: 1;
        // width: 96%;
        margin-top: 2px !important;
        //margin: 0 auto !important;
    }
    textarea[name='address'] + div {
        left: 64% !important;
    }
}

.screen_max_width {
    max-width: 1440px;
    margin: 0 auto;
}

// page header css
.pageHeaderText {
    font-weight: bold !important;
    font-size: 18px !important;
    letter-spacing: 0px !important;
    line-height: 0 !important;
    color: #262626 !important;
    margin-top: 24.5px !important;
    margin-bottom: 22px !important;
}

.ATMToastMessage {
    max-width: fit-content !important;
}
// .modals.dimmer {
//     background-color: rgba(1, 1, 1, 0.5) !important;
// }

.inverted.Change:hover {
    background-color: #ff0000 !important;
}

.contractsTable {
    // margin-bottom: 10px !important;
    table {
        thead {
            // font: Heavy 13px #000000cc !important;
            tr {
                td {
                    font-size: 13px !important;
                    color:#000000cc !important;
                }
            }
            th {
                color: rgba(0, 0, 0, 0.8) !important;
            }
        }
    }
}

.plusIcon i.icon:before {
    font-weight: 200 !important;
    color: #ffffff;
    width: 11.14px !important;
}

 i.exclamation.icon:before {
    font-weight: 200 !important;
    color: #ffffff;
    width: 11.14px !important;
}

.ui.pointing.label.contracts {
    margin-top: 6px;
}

.ORGDataTable-module__rowPerPage___8N15C {
    padding-top: 7px !important;
}
.modals {
    .modal {
        // .header {
        //     font-size: 18px !important;
        // }
        .actions {
            padding: 0.7rem 1rem !important;
            border-top: none !important;
        }
    }
}
// .ui.basic.blue.button {
//     color: #009bda !important;
//     font-weight: 700;
//     font-size: 14px !important;
//     //line-height: 17px !important;
//     border: 1px solid #009bda !important;
//     border-radius: 4px;
//     box-shadow: none !important;
//     padding: 10px 21px !important;
// }
// .menu a.active {
//     color: #011c71 !important;
//     border-color: #011c71 !important;
// }

.disabled{
    i.close{
        display: none !important;
    }
}

.tab-menu{ //this is for contact & admin user
    height: max-content;
    .segment {
        border: 0 !important;
        box-shadow: none !important;
        // margin-top: 30px;
        padding-right: 0;
        padding-left: 0;
        margin-left: 0;
        margin-right: 0;
        .grid {
            padding: 0;
            //margin: 0 !important;
        }
        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.ui.table thead th {
    color: #000000 !important;
    opacity: 1 !important;
}

:global {
    .rowPerPage {
        label {
            position: relative;
        }
    }

}

.ui.vertical.menu .active.item { //for mobile menu active color
    color: #031858 !important;
}
