.wrapper {
    //height: calc(100vh - 144px);
    display: flex;
    flex-direction: row;
    background-color: #f7f7f7;
    // padding-left: 20px;
    // padding-right: 20px;

    h1.header {
        padding-top: 4em !important;
        font-size: 4em;
    }
    table {
        tr {
            th {
                border-left: none !important;
            }
            td {
                border-left: none;
            }
        }
        i {
            background: transparent;
        }
    }
}

.pageName {
    padding-top: 22.5px;
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
}

.headerContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.content {
    margin-top: 20px !important;
    margin-bottom: 11px !important;
    // margin: 20px !important;
    // padding-top: '32px';
    // padding-bottom: '35px';
}

.headerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
    margin-left: 20px;
    margin-bottom: -3px;
    margin-right: 10px;
}

.alignCenter {
    display: flex;
    align-items: center;
}

.red {
    color: #d01919;
}

.table1 tr:last-child {
    background-color: #ffa50016;
    font-weight: bold !important;
    color: #000000dd !important;
}

.table1 tr td {
    border-left: 0px !important;
}

.table1 tr th {
    border-left: 0px !important;
    border-radius: 0px !important;
}

.table1 tr td:first-child,
.table1 tr th:first-child {
    width: 174px !important;
}

.table1 tr td:last-child,
.table1 tr th:last-child {
    width: 100px !important;
}

#head > span {
    text-align: center !important;
}

.table1 tr td,
.table1 tr th {
    width: 95px !important;
}

.table2 tr:not(:last-child) td:not(:first-child) {
    color: #d01919;
}

.table2 tr:last-child {
    color: #000000 !important;
}

.tabeheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f9fafb;
    height: 42px;
    // font-weight: bold;
}

.alignMessage{
    text-align: center;
    font-size: 1em;
    margin: 78px;
    color: rgba(109, 109, 109, 0.87);
}

.green {
    color: #2dad4e;
}

.gridTable {
    table {
        tr {
            th {
                border-left: none !important;
            }
            td {
                border-left: none !important;
            }
        }
        i {
            background: transparent;
        }
    }
}

.report {
    i {
        background: transparent !important;
        position: relative;
        // top: 3px;
        color: #4d4d4d;
        &::before {
            font-size: 12px;
        }
    }
}

.landingContainer {
    display: flex !important;
    flex-direction: row !important;
    min-height: 300px;
    justify-content: center !important;
    align-items: center !important;
    color: #646464;
}

.tableTop {
    margin-top: 2rem !important;
}

.purchaseOrderTable td {
    border-left: 0px;
    // text-align: center !important;
}

.purchaseOrderTable td:nth-child(1) {
    border-left: 1px solid #e2e2e3 !important;
    width: 173px !important;
}

.purchaseOrderTable {
    td:nth-child(2) {
        width: 72px !important;
    }
    td:nth-child(3) {
        width: 72px !important;
    }
    td:nth-child(4) {
        width: 72px !important;
    }
    td:nth-child(5) {
        width: 72px !important;
    }
    td:nth-child(6) {
        width: 72px !important;
    }
    td:nth-child(7) {
        width: 72px !important;
    }
    td:nth-child(8) {
        width: 72px !important;
    }
    td:nth-child(9) {
        width: 72px !important;
    }
    td:nth-child(10) {
        width: 72px !important;
    }
    td:nth-child(11) {
        width: 72px !important;
    }
    td:nth-child(12) {
        width: 72px !important;
    }
    td:nth-child(13) {
        width: 72px !important;
    }
    td:nth-child(14) {
        width: 72px !important;
    }
    td:nth-child(15) {
        width: 72px !important;
    }
}

.rowTable table tr td {
    border-left: 0px;
}

.rowTable tr td:nth-child(1),
.rowTable tr th:nth-child(1) {
    width: 182px !important;
}

.rowTable tr td:nth-child(2),
.rowTable tr th:nth-child(2) {
    width: 131px !important;
}

.rowTable tr td:nth-child(3),
.rowTable tr th:nth-child(3) {
    width: 143px !important;
}

.rowTable tr td:nth-child(4),
.rowTable tr th:nth-child(4) {
    width: 161px !important;
}

.rowTable tr td:nth-child(5),
.rowTable tr th:nth-child(5) {
    width: 173px !important;
}

.rowTable tr td:nth-child(6),
.rowTable tr th:nth-child(6) {
    width: 146px !important;
}

.rowTable tr td:nth-child(7),
.rowTable tr th:nth-child(7) {
    width: 141px !important;
}

.rowTable tr td:nth-child(8),
.rowTable tr th:nth-child(8) {
    width: 107px !important;
}

.rowTable tr td:nth-child(9),
.rowTable tr th:nth-child(9) {
    width: 107px !important;
}

.rowTable tr td:nth-child(10),
.rowTable tr th:nth-child(10) {
    width: 94px !important;
}

.rowTable1 tr td:nth-child(1),
.rowTable1 tr th:nth-child(1) {
    width: 222px !important;
}

.rowTable1 tr td:nth-child(2),
.rowTable1 tr th:nth-child(2) {
    width: 167px !important;
}

.rowTable1 tr td:nth-child(3),
.rowTable1 tr th:nth-child(3) {
    width: 205px !important;
}

.rowTable1 tr td:nth-child(4),
.rowTable1 tr th:nth-child(4) {
    width: 227px !important;
}

.rowTable1 tr td:nth-child(5),
.rowTable1 tr th:nth-child(5) {
    width: 197px !important;
}

.rowTable1 tr td:nth-child(6),
.rowTable1 tr th:nth-child(6) {
    width: 131px !important;
}

.rowTable1 tr td:nth-child(7),
.rowTable1 tr th:nth-child(7) {
    width: 114px !important;
}

.rowTable1 tr td:nth-child(8),
.rowTable1 tr th:nth-child(8) {
    width: 97px !important;
}

.pinkBackground {
    background: #d0191908 !important;
}

.totals {
    background: #ffa50016 !important;
    font-weight: bold;
}

.container {
    margin-left: unset !important;
    margin-right: unset !important;
    p {
        text-align: left;
        font: Bold 12px/15px;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 0.5);
        margin-left: 35px;
    }

    .segment {
        background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
        border: 1px solid rgba(230, 230, 231, 1);
        border-radius: 4px;
        table {
            // background: rgba(249, 250, 251, 1) 0% 0% no-repeat padding-box;
            border: 1px solid rgba(226, 226, 227, 1);
            border-radius: 4px 4px 0px 0px;
            thead {
                th {
                    background: rgba(249, 250, 251, 1) 0% 0% no-repeat
                        padding-box !important;
                    border: 1px solid rgba(226, 226, 227, 1) !important;
                    border-radius: 4px 4px 0px 0px !important;
                    text-align: left !important;
                    // font: Heavy 14px/24px !important;
                    // letter-spacing: 0px !important;
                    // color: rgba(0, 0, 0, 0.8) !important;
                }
            }
            tbody {
                td {
                    text-align: left;
                    // font: Regular 13px/24px;
                    // letter-spacing: 0px;
                    // color: rgba(0, 0, 0, 0.8);
                    h5 {
                        text-align: left;
                        // font: Bold 14px/24px;
                        // letter-spacing: 0px;
                        // color: rgba(0, 0, 0, 0.8);
                    }
                }
            }
        }
    }
}

.notHeader {
    display: inline-block;
    margin: 0 0 0 !important;
    line-height: 22px !important;
    font-size: 18px;
}

.notIcon {
    background: rgba(167, 0, 181, 1) 0% 0% no-repeat padding-box;
    top: 109px;
    left: 21px;
    width: 22px;
    height: 26px;
}

.inDays {
    padding: 0.5em;
    background: rgba(247, 225, 207, 1) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font-weight: bolder;
}

// .reportDD {
//     font: Heavy 14px/24px !important;
// }

.infoIcon {
    color: #848080 !important;
    cursor: pointer;
}

.reportTooltip {
    width: 12rem !important;
    // font-size: 12px !important;
    // font: Regular 12px;
    background-color: #535353 !important;
    &::before {
        background-color: #535353 !important;
    }
}

.tooltipi {
    background: #848080 !important;
}

.unUpdatedColumn {
    color:  red;
}

.updated {
    //    color: green;
    //    background: #d01919;
}

.unUpdated {
    //  color: red
}

.rowTable.tr.updated {
    // background-color: #535353 !important;
    padding: 0px;
}

.rowTable.table tbody tr td {
    padding: 0px !important; //
}

.rowTable tr td {
    padding: 0px !important;
    // font-family: Lato;
}

.rowTable tr td td {
    padding: 0.78571429em 0.78571429em !important;
    // padding: 10px !important;
}

.rowTable1.tr.updated {
    // background-color: #535353 !important;
    padding: 0px;
}

.rowTable1.table tbody tr td {
    padding: 0px !important; //
}

.rowTable1 tr td {
    padding: 0px !important;
    // font-family: Lato;
}

.rowTable1 tr td td {
    padding: 0.78571429em 0.78571429em !important;
    // padding: 10px !important;
}

.adminSummaryTotal {
    color: 'red';
}

.monthTotals {
    background-color: #ffa50016;
    font-weight: bold;
    color: #000000DD !important
}
.sdccontact {
    background-color: white !important;
    font-weight: bold;
    color: #000000DD !important
}

.alignsdcName {
    min-width: 185px !important;
    font-weight: normal;
    vertical-align:top !important;
    border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.algnSubcontractor {
    min-width: 160px !important;
    vertical-align:center !important;
}

.alignPrimeData {
    min-width: 180px !important;
    vertical-align:top !important;
}

.adminMonthSummaryTable table tr td:nth-child(1) {
    font-weight: bold !important;
    color: red;
}

.verFollowupLabel {
    margin-right: 10px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    font-family: 'Lato' !important;
    color: '#333333DE' !important;
}

.verFollowupFields {
    font-size: 12 !important;
    font-weight: 'bold' !important;
    color: '#606060' !important;
    max-width: 55rem !important;
    min-width: 10rem !important;
}

.verFollowupDiv {
    display: flex; 
    flex-wrap: wrap; 
    padding-left: 20px;    
    gap: 6px;
}

.reportFiltersWrapper {
    display: flex; 
    flex-wrap: wrap; 
    align-items: center;
    gap: 6px;
}

.verFollowupDivWrapper {
    display: flex; 
    align-items: center;
    justify-content: center;
}

.datepicker {
    min-width: 17rem;
    :global {
        .field:first-of-type {
            display: block !important;
            margin: 0 !important;
        }
        i.close {
            &::before {
                top: 50% !important;
                color: initial !important;
                font-weight: normal;
            }
        }
    }
}

.unUpdatedParent {
    background-color: #D0191908;
    font-weight: bold;
    color: #D01919;
}

.updatedParent {
    background-color: #fff;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
}
.dimmed {
    position: relative;
    content: " ";
    display: block;
    position: absolute;
    height: 100%;
    top: 77px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    z-index: 99;
    .dimmedText {
        position: absolute;
        top: 35%;
        text-align: center;
        color: red;
        font-size: 18px;
        width: 100%;
        background: #fff;
        left: 0;
        font-weight: 600;
        padding: 15px 200px;
        z-index: 99;
    }
  }